import React from 'react';

export default () => {
  return (
    <svg width="194" height="42" viewBox="0 0 194 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.7097 27.8663H40.711L40.4569 21.474L36.6958 26.6678H36.2892L32.5282 21.474L32.1724 27.8663H29.1737L29.8853 15.3313H30.7493L36.4417 22.8224L42.0833 15.3313H42.9473L43.7097 27.8663Z"
        fill="#181833"/>
      <path
        d="M53.417 19.6767C54.0777 20.276 54.4335 21.2249 54.4335 22.5233V27.4674C52.8071 27.9169 51.2823 28.1666 49.91 28.1666C46.9114 28.1666 45.3866 27.2177 45.3866 25.2701C45.3866 23.3723 46.9114 22.4734 49.4526 22.4734C49.9609 22.4734 50.6724 22.5233 51.6889 22.6732V22.4734C51.6889 21.8741 51.5364 21.4746 51.1807 21.2748C50.8249 21.0751 50.2658 20.9752 49.5034 20.9752C48.5886 20.9752 47.6229 21.125 46.6064 21.3747L46.3014 19.427C47.6737 19.0774 48.8935 18.8777 50.0625 18.8777C51.6381 18.8277 52.7563 19.1274 53.417 19.6767ZM50.0117 26.169C50.5708 26.169 51.1298 26.1191 51.6889 26.0691V24.3212C50.9774 24.2213 50.3675 24.1714 49.8592 24.1714C48.6902 24.1714 48.0803 24.521 48.0803 25.1702C48.0803 25.8693 48.6394 26.169 50.0117 26.169Z"
        fill="#181833"/>
      <path
        d="M62.82 19.0773L62.3626 21.1748C61.6002 21.025 60.9395 20.975 60.3296 21.025C60.0754 21.025 59.6688 21.1248 59.1606 21.2247V27.8668H56.416V19.8763C57.9916 19.1772 59.5164 18.8276 60.9395 18.8276C61.7527 18.7776 62.3626 18.8775 62.82 19.0773Z"
        fill="#181833"/>
      <path
        d="M70.6471 27.8668L68.1058 23.8716H67.0893V27.8668H64.3447V14.233L67.0893 14.0832V21.7741H68.1566L70.5962 19.0773H73.6457L70.2405 22.7229L73.7982 27.9168H70.6471V27.8668Z"
        fill="#181833"/>
      <path
        d="M76.8988 24.1709C77.1021 25.4194 78.0169 26.0187 79.6433 26.0187C80.4565 26.0187 81.4731 25.8688 82.6929 25.6191L82.9978 27.3671C82.642 27.5668 82.083 27.7166 81.4222 27.8665C80.7615 28.0163 80.05 28.1162 79.3384 28.1162C77.7628 28.1162 76.4922 27.6667 75.5265 26.8177C74.6116 25.9687 74.1034 24.87 74.1034 23.5216C74.1034 22.1732 74.5608 21.0246 75.4757 20.1756C76.3905 19.2767 77.5595 18.8772 79.0334 18.8772C80.4057 18.8772 81.4731 19.2767 82.3371 20.0258C83.2011 20.8248 83.6077 21.8237 83.6077 23.0222C83.6077 23.4218 83.5569 23.8213 83.5061 24.1709H76.8988V24.1709ZM80.914 22.4229C80.914 22.0234 80.7615 21.6239 80.4566 21.3243C80.1516 21.0246 79.6433 20.8748 78.9318 20.8748C77.8645 20.8748 77.1529 21.3742 76.8988 22.4229H80.914Z"
        fill="#181833"/>
      <path
        d="M91.3328 25.6699L91.5361 27.5676C90.6212 27.8673 89.7064 28.0171 88.7915 28.0171C87.9783 28.0171 87.3176 27.7674 86.8093 27.3179C86.3011 26.8685 86.0978 26.1193 86.0978 25.1205V21.0254H84.6747L84.8272 19.0278H86.0978V16.7805L88.8424 16.4309V19.0278H91.5869L91.4344 21.0254H88.8424V24.8209C88.8424 25.4701 89.0965 25.7198 89.808 25.7198C90.2655 25.7698 90.7737 25.7698 91.3328 25.6699Z"
        fill="#181833"/>
      <path
        d="M112.07 27.8663H109.071L108.817 21.474L105.056 26.6678H104.65L100.939 21.474L100.584 27.8663H97.5848L98.2964 15.3313H99.1604L104.853 22.8224L110.494 15.3313H111.358L112.07 27.8663Z"
        fill="#181833"/>
      <path
        d="M121.777 19.6766C122.438 20.2759 122.794 21.2247 122.794 22.5232V27.4673C121.167 27.9168 119.643 28.1665 118.27 28.1665C115.272 28.1665 113.747 27.2176 113.747 25.2699C113.747 23.3722 115.272 22.4732 117.813 22.4732C118.321 22.4732 119.033 22.5232 120.049 22.673V22.4732C120.049 21.874 119.897 21.4744 119.541 21.2747C119.185 21.0749 118.626 20.975 117.864 20.975C116.949 20.975 115.983 21.1248 114.967 21.3745L114.662 19.4269C116.034 19.0773 117.254 18.8775 118.423 18.8775C119.998 18.8276 121.117 19.1272 121.777 19.6766ZM118.372 26.1688C118.931 26.1688 119.49 26.1189 120.049 26.069V24.321C119.338 24.2212 118.728 24.1712 118.22 24.1712C117.051 24.1712 116.441 24.5208 116.441 25.17C116.492 25.8692 117.051 26.1688 118.372 26.1688Z"
        fill="#181833"/>
      <path
        d="M131.18 27.8668L128.639 23.8716H127.622V27.8668H124.878V14.233L127.622 14.0832V21.7741H128.69L131.129 19.0773H134.179L130.773 22.7229L134.331 27.9168H131.18V27.8668Z"
        fill="#181833"/>
      <path d="M138.397 17.529H135.601V14.8322H138.397V17.529ZM138.346 27.8667H135.601V19.0772H138.346V27.8667Z"
            fill="#181833"/>
      <path
        d="M149.528 22.1239V27.8671H146.784V22.7232C146.784 22.0739 146.682 21.6245 146.529 21.3748C146.326 21.1251 145.97 20.9753 145.361 20.9753C144.7 20.9753 144.039 21.0751 143.277 21.225V27.917H140.532V19.9265C142.209 19.2273 143.887 18.8778 145.665 18.8778C148.105 18.8278 149.528 19.9764 149.528 22.1239Z"
        fill="#181833"/>
      <path
        d="M160.608 28.9156C160.608 29.8645 160.151 30.6636 159.185 31.2628C158.219 31.8621 156.949 32.1618 155.322 32.1618C153.899 32.1618 152.73 31.9121 151.917 31.4626C151.053 30.9632 150.646 30.3639 150.646 29.6148C150.646 28.8158 151.104 28.1665 151.968 27.6172C151.663 27.3175 151.46 26.8681 151.46 26.3687C151.46 25.7194 151.815 25.1701 152.578 24.7206C151.815 24.1213 151.409 23.2723 151.409 22.2236C151.409 21.1748 151.815 20.3259 152.578 19.7266C153.34 19.1273 154.357 18.8276 155.627 18.8276C156.186 18.8276 156.745 18.8776 157.254 19.0274C157.609 17.6291 158.473 16.9299 159.795 16.9299H160.405L160.456 19.0274H159.897C159.337 19.0274 158.931 19.2771 158.728 19.7765C159.388 20.3758 159.744 21.1748 159.744 22.1737C159.744 23.2224 159.388 24.0215 158.626 24.6207C157.864 25.22 156.847 25.5696 155.576 25.5696C155.017 25.5696 154.458 25.5197 154.001 25.3699C153.848 25.4697 153.798 25.6196 153.798 25.8193C153.798 26.069 153.95 26.2188 154.306 26.2688C154.357 26.2688 154.814 26.3187 155.576 26.3687C156.339 26.4685 157.152 26.5185 157.914 26.6184C159.693 26.8181 160.608 27.5672 160.608 28.9156ZM157.864 29.2652C157.864 28.8157 157.559 28.616 156.847 28.5161L153.798 28.2165C153.493 28.5161 153.289 28.8158 153.289 29.1154C153.289 29.7646 154.001 30.2141 155.475 30.2141C157.05 30.2141 157.864 29.8645 157.864 29.2652ZM154.153 22.2236C154.153 23.1225 154.712 23.6719 155.526 23.6719C156.44 23.6719 156.898 23.1225 156.898 22.2236C156.898 21.3247 156.44 20.8752 155.526 20.8752C154.611 20.8253 154.153 21.3746 154.153 22.2236Z"
        fill="#181833"/>
      <path
        d="M175.652 18.9771C175.652 20.3255 175.195 21.4241 174.229 22.2731C173.263 23.1221 171.891 23.5716 170.112 23.5716C169.655 23.5716 169.146 23.5716 168.537 23.5217V27.8665H166.808V15.3813C167.063 15.3813 167.723 15.3314 168.791 15.3314C169.858 15.2815 170.57 15.2815 170.925 15.2815C174.076 15.2815 175.703 16.48 175.652 18.9771ZM173.873 19.0769C173.873 18.228 173.619 17.6287 173.162 17.2791C172.704 16.9295 171.891 16.7297 170.722 16.7297C170.57 16.7297 169.807 16.7797 168.537 16.8296V21.9735C169.197 22.0234 169.756 22.0234 170.214 22.0234C172.653 22.0234 173.873 21.0246 173.873 19.0769Z"
        fill="#181833"/>
      <path
        d="M183.174 19.027L183.124 20.5252C182.361 20.4253 181.65 20.4253 181.04 20.4753C180.633 20.5252 180.074 20.6251 179.413 20.8748V27.9164H177.736V20.0258C178.549 19.6263 179.312 19.3266 179.922 19.1269C180.532 18.977 181.243 18.8772 181.955 18.8772C182.158 18.8772 182.565 18.9271 183.174 19.027Z"
        fill="#181833"/>
      <path
        d="M185.613 26.7676C184.699 25.9186 184.241 24.82 184.241 23.4716C184.241 22.1731 184.699 21.0744 185.664 20.2254C186.579 19.3265 187.748 18.927 189.12 18.927C190.543 18.927 191.763 19.3764 192.627 20.2254C193.542 21.0744 194 22.1731 194 23.5215C194 24.82 193.542 25.9186 192.576 26.7676C191.611 27.6666 190.493 28.0661 189.12 28.0661C187.646 28.0661 186.477 27.6166 185.613 26.7676ZM189.12 26.6678C190.035 26.6678 190.798 26.3681 191.407 25.7688C191.967 25.1695 192.271 24.4204 192.271 23.5215C192.271 22.6226 191.967 21.8735 191.407 21.2742C190.848 20.6749 190.086 20.3752 189.12 20.3752C188.155 20.3752 187.392 20.6749 186.833 21.2742C186.274 21.8735 185.969 22.6226 185.969 23.5215C185.969 24.4204 186.274 25.1695 186.833 25.7688C187.443 26.3681 188.205 26.6678 189.12 26.6678Z"
        fill="#181833"/>
      <path
        d="M18.8053 14.5327V9.33888H18.5004V14.5327H16.2641L12.8588 17.8787V7.89061H10.6733V0H10.3684V7.89061H8.13204V17.8787L4.72675 14.5327H2.49044V9.33888H2.18549V14.5327H0V27.9667H2.18549V35.258H2.49044V27.9667H4.72675V20.0761L8.13204 23.4221V31.912H10.3684V42H10.6733V31.912H12.8588V23.4221L16.2641 20.0761V27.9667H18.5004V35.258H18.8053V27.9667H20.9908V14.5327H18.8053Z"
        fill="#33CC66"/>
    </svg>

  );
};
