import React from 'react';

// Import colors from constants or use currentColor
// If icon uses more than one adjustable color you can pass colors from props
// BUT you must always provide default values for color props

export default () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.522 1.88399L8 0.666656L13.478 1.88399C13.626 1.9169 13.7584 1.9993 13.8533 2.11759C13.9482 2.23589 14 2.383 14 2.53466V9.19266C14 9.85115 13.8373 10.4994 13.5266 11.08C13.2158 11.6606 12.7666 12.1554 12.2187 12.5207L8 15.3333L3.78133 12.5207C3.23352 12.1555 2.78431 11.6607 2.47357 11.0803C2.16282 10.4999 2.00016 9.8517 2 9.19332V2.53466C2.00003 2.383 2.05176 2.23589 2.14666 2.11759C2.24156 1.9993 2.37396 1.9169 2.522 1.88399V1.88399ZM3.33333 3.06932V9.19266C3.33334 9.63163 3.44172 10.0638 3.64884 10.4508C3.85597 10.8379 4.15543 11.1678 4.52067 11.4113L8 13.7313L11.4793 11.4113C11.8445 11.1679 12.1439 10.8381 12.351 10.4511C12.5581 10.0642 12.6665 9.63218 12.6667 9.19332V3.06932L8 2.03332L3.33333 3.06932ZM8 7.33332C7.55797 7.33332 7.13405 7.15773 6.82149 6.84517C6.50893 6.53261 6.33333 6.10868 6.33333 5.66666C6.33333 5.22463 6.50893 4.80071 6.82149 4.48815C7.13405 4.17558 7.55797 3.99999 8 3.99999C8.44203 3.99999 8.86595 4.17558 9.17851 4.48815C9.49107 4.80071 9.66667 5.22463 9.66667 5.66666C9.66667 6.10868 9.49107 6.53261 9.17851 6.84517C8.86595 7.15773 8.44203 7.33332 8 7.33332V7.33332ZM5.018 10.6667C5.09881 9.93226 5.44779 9.25351 5.99805 8.76047C6.54832 8.26744 7.26117 7.9948 8 7.9948C8.73883 7.9948 9.45168 8.26744 10.0019 8.76047C10.5522 9.25351 10.9012 9.93226 10.982 10.6667H5.018Z"
        fill="black"/>
    </svg>
  );
};
